import React from "react";

const LINKS = {
    INSTAGRAM: "https://www.instagram.com/playtimepassgames/",
    FACEBOOK: "https://www.facebook.com/www.timepass.games",
    DISCORD: "https://discord.com/channels/1065196859582316575/1065196860542820416\n",
    TWITTER: "https://x.com/playtimepass",
    LINKEDIN:
        "https://www.linkedin.com/company/timepassgames/posts/?feedView=all",
};
const TimepassInfoBanner = () => {
    return (
        <div className={"w-[100%]"} style={{
            backgroundImage: "url('footerBackground.svg')",
            backgroundSize: "cover"
        }}>
            <div
                className="max-w-[80vw] xl:max-w-[70vw] max-sm:max-w-[90vw] mx-auto p-0 md:p-8 lg:p-2 pb-10 text-white font-satoshi">
                <div className="flex flex-col md:flex-row items-center md:items-start justify-between md:gap-4">
                    <div className="flex-shrink-0">
                        <img src="/buisness-logo.svg" alt="Business Logo" className="mx-auto sm:mx-0 md:mt-6"/>
                    </div>

                    <div className="flex flex-wrap justify-center sm:justify-start gap-2 mt-4 sm:mt-0">

                        <a href={LINKS.FACEBOOK} target={"_blank"} rel="noopener noreferrer">
                            <img
                                src="Facebook.svg"
                                alt="Facebook"
                                className="w-12 sm:w-16"
                            />
                        </a>
                        <a href={LINKS.TWITTER} target={"_blank"} rel="noopener noreferrer">
                            <img src="X.svg" alt="Twitter" className="w-12 sm:w-16"/>
                        </a>
                        <a href={LINKS.INSTAGRAM} target={"_blank"} rel="noopener noreferrer">
                            <img
                                src="instagram.svg"
                                alt="Instagram"
                                className="w-12 sm:w-16"
                            />
                        </a>
                        <a href={LINKS.DISCORD} target={"_blank"} rel="noopener noreferrer">
                            <img
                                src="Discord.svg"
                                alt="Discord"
                                className="w-12 sm:w-16"
                            />
                        </a>
                        <a href={LINKS.LINKEDIN} target={"_blank"} rel="noopener noreferrer">
                            <img
                                src="Linkedin.svg"
                                alt="LinkedIn"
                                className="w-12 sm:w-16"
                            />
                        </a>
                    </div>
                </div>
                <div className="flex flex-wrap md:flex-nowrap justify-between">
                    <section className="w-full mb-8 bg-gradient-to-br from-[#38c0c02e] to-[#100e1021] rounded-xl p-7">
                        <img src="abouticon1.svg"/>

                        <img src="bluestroke.svg" className={"mt-2"}/>
                        <p className="text-md mt-1">
                            Timepass is a plug-and-play gaming platform that any app or website can integrate to bring
                            casual gaming for its users.
                        </p>
                        <p className="text-md mt-1">
                            Over 5,000 products from more than 70 countries have integrated Timepass. These include Circles, BeBetta, Elo Elo, Stan and BhaiFi..
                        </p>
                        <p className="text-md mt-1">
                            Games and trivia increase user engagement significantly within all kinds of apps and
                            websites,
                            besides opening a new stream of advertising revenue. Timepass take 30 minutes to integrate
                            and
                            can be used for free: both by the products integrating them and end users
                        </p>
                        <p className="text-md mt-1">
                            Increase ad revenue and engagement on your app / website with games, quizzes, astrology, and
                            cricket content. Visit:<a href={"https://timepass.business/"}>https://timepass.business/</a>
                        </p>
                    </section>
                </div>
                <div
                    className="container grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-6 ">
                    <div>
                        <h5 className="font-bold mb-4 text-white text-left">Popular Games</h5>
                        <div className="space-y-2 text-left list-none m:0">
                            <li><a href="/bottle-flip" className="text-decoration-none text-white">Bottle Flip</a></li>
                            <li><a href="/watermelon-fruit-2048" className="text-decoration-none text-white">Watermelon
                                Fruit</a></li>
                            <li><a href="/car-out" className="text-decoration-none text-white">Car Out</a></li>
                            <li><a href="/draw-parking" className="text-decoration-none text-white">Draw Parking</a>
                            </li>
                        </div>
                    </div>

                    {/* Terms and Services */}
                    <div>
                        <h5 className="font-bold mb-4 text-white">Terms and Services</h5>
                        <div className="space-y-2 text-left list-none m:0">
                            <li><a href="https://help.timepass.games/terms" target={"_blank"}
                                   className="text-decoration-none text-white">Terms</a></li>
                            <li><a href="https://help.timepass.games/privacy" target={"_blank"}
                                   className="text-decoration-none text-white">Privacy</a></li>
                        </div>
                    </div>

                    {/* Support */}
                    <div>
                        <h5 className="font-bold mb-4 text-white">Support</h5>
                        <div className="space-y-2 text-left list-none m:0">
                            <li><a href="https://help.timepass.games/support" target={"_blank"}
                                   className="text-decoration-none text-white">Support</a></li>

                        </div>
                    </div>

                    {/* Documentation */}
                    <div>
                        <h5 className="font-bold mb-4 text-white">Documentation</h5>
                        <div className="space-y-2 text-left list-none m:0">
                            <li><a href="https://docs.developers.timepass.games/" target={"_blank"}
                                   className="text-decoration-none text-white">Documentation</a></li>
                            <li><a href="https://developers.timepass.games/" target={"_blank"}
                                   className="text-decoration-none text-white">Developer Dashboard</a></li>
                        </div>
                    </div>

                    {/*<div>*/}
                    {/*    <h5 className="font-bold mb-4 text-white">Other Pages</h5>*/}
                    {/*    <div className="space-y-2 text-left list-none m:0">*/}
                    {/*        <li><a href="#" className="hover:underline">Blog</a></li>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className={"bg-[#ffffff33] h-[1px] w-[70vw] mx-auto mt-2"}>

            </div>
            <div className={"w-[100%] text-center font-satoshi text-white py-4"}>
                © 2024, Simple Viral Games
            </div>
        </div>
    );
};

export default TimepassInfoBanner;
