/* global googletag */

import { useEffect, useState, useRef } from 'react';

export const InterstitialAdComponent = ({ setShowInterstitialAd }) => {
  const [isTimerComplete, setIsTimerComplete] = useState(false);
  const [countdown, setCountdown] = useState(6);
  const [isAdLoaded, setIsAdLoaded] = useState(false);
  const timerRef = useRef(null);
  const adSlotRef = useRef(null);
    const interstitialAdSlot =  process.env.REACT_APP_INTERSTITIAL_AD_SLOT
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://adunit.datawrkz.com/production/interstitial/banner/static/interstitial_v1.min.js';
    script.async = true;
 
    script.onload = () => {
      window.googletag = window.googletag || { cmd: [] };

      window.googletag.cmd.push(() => {
        adSlotRef.current = googletag.defineSlot(interstitialAdSlot, [320, 480], 'RS60_TimepassGames_DWINT_640x480_231024')
          .addService(googletag.pubads());

        googletag.pubads().set('page_url', 'https://timepass.games/');
        googletag.enableServices();
        googletag.display('RS60_TimepassGames_DWINT_640x480_231024');

        googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          if (event.slot === adSlotRef.current && !event.isEmpty) {
            setIsAdLoaded(true);
            startCountdown();
          } else {
            setIsAdLoaded(false);
            setIsTimerComplete(true);
          }
        });
      });
    };

    document.body.appendChild(script);

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (adSlotRef.current) {
        googletag.destroySlots([adSlotRef.current]);
      }
    };
  }, []);

  const startCountdown = () => {
    if (countdown > 0) {
      timerRef.current = setTimeout(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          if (newCountdown === 0) {
            setIsTimerComplete(true);
          }
          return newCountdown;
        });
        startCountdown();
      }, 1000);
    }
  };

  return (
    <>
      <script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"/>
      <div className="fixed inset-0 flex justify-center items-center bg-white/80 backdrop-blur-sm z-[1000] font-satoshi">
        <div className="relative w-[80vw] h-[80vh] max-w-[640px] max-h-[480px] flex items-center justify-center bg-gray-100 shadow-lg rounded-md">
          <div
            id='RS60_TimepassGames_DWINT_640x480_231024'
            className="flex items-center justify-center w-full h-full"
          ></div>

          {isAdLoaded && !isTimerComplete && countdown > 0 && (
            <div className="absolute top-1 right-2 bg-white text-black text-3xl rounded-full w-10 h-10 flex items-center justify-center">
              {countdown}
            </div>
          )}

          {isTimerComplete && (
            <button
              className="absolute top-5 right-5 bg-transparent text-2xl text-gray-800 hover:text-red-500 cursor-pointer transition-opacity duration-500 ease-in-out opacity-100"
              onClick={() => {
                setShowInterstitialAd(false);
              }}
            >
                X
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default InterstitialAdComponent;