import { useEffect, useState } from 'react'
import toast, { ErrorIcon } from 'react-hot-toast'

export const RewardedAdComponent = ({ setShowVideoRewardedAd, notifyPurchaseCompleted, gameRewardId }) => {
  const [rewardPayload, setRewardPayload] = useState(null)
  const [countdown, setCountdown] = useState(3)
  const [rewardedLoaded, setRewardedLoaded] = useState(false)
  let rewardGranted = false
  const rewardedAdSlot = process.env.REACT_APP_REWARDED_AD_SLOT

  useEffect(() => {
    // Dynamically load GPT script
    const script = document.createElement('script')
    script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
    script.async = true
    script.onload = () => {
      console.log('GPT script loaded')

      window.googletag = window.googletag || { cmd: [] }
      let rewardedSlot = null

      window.googletag.cmd.push(() => {
        rewardedSlot = window.googletag.defineOutOfPageSlot(
          rewardedAdSlot,
          window.googletag.enums.OutOfPageFormat.REWARDED
        )

        if (rewardedSlot) {
          rewardedSlot.addService(window.googletag.pubads())

          window.googletag.pubads().addEventListener("rewardedSlotReady", (event) => {
            setRewardedLoaded(true)
            event.makeRewardedVisible()
          })

          window.googletag.pubads().addEventListener("rewardedSlotClosed", () => {
            closeModal()
          })

          const handleRewardGranted = (event) => {
            if (!rewardGranted) {
              rewardGranted = true
              setRewardPayload(event.payload)
              notifyPurchaseCompleted(gameRewardId, 'ad')
              closeModal()

              window.googletag.pubads().removeEventListener("rewardedSlotGranted", handleRewardGranted)
            }
          }

          window.googletag.pubads().addEventListener("rewardedSlotGranted", handleRewardGranted)

          window.googletag.pubads().addEventListener("slotRenderEnded", (event) => {
            if (event.slot === rewardedSlot && event.isEmpty) {
              toast.error('No ads Available', { icon: <ErrorIcon /> })

              const timer = setInterval(() => {
                setCountdown((prev) => {
                  if (prev <= 1) {
                    clearInterval(timer)
                    closeModal()
                  }
                  return prev - 1
                })
              }, 300)
            }
          })

          window.googletag.enableServices()
          window.googletag.display(rewardedSlot)
        }
      })
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      if (rewardedAdSlot) {
        window.googletag.destroySlots([rewardedAdSlot])
      }
    }
  }, [])

  function closeModal() {
    setShowVideoRewardedAd(false)
    setRewardedLoaded(false)
  }

  return (
    <>
      {rewardedLoaded && (
        <div className="fixed inset-0 flex justify-center items-center bg-white/80 backdrop-blur-sm z-[1000] font-satoshi">
          <div>
            <span className="loader_black"></span>
          </div>
        </div>
      )}
    </>
  )
}

export default RewardedAdComponent
